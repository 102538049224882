$(document).ready(function() {


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * mobileNavigation
	// *
	// *
    $(".js-nav-mobile-button").click(function(){
        $(".js-nav-mobile-button").toggleClass("active");
        $(".js-nav-mobile").toggleClass("active");
        $('html, body').toggleClass('freeze');
    });

    $('.js-nav-mobile .parent span').click(function(){
		$(this).closest('li').toggleClass('is-open is-closed').find('> ul').slideToggle();
	});

	// trigger active menü-parents
	$('.js-nav-mobile .parent.active > div > span').trigger("click");


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
        window.location = $(this).attr("href");
        return false;
    }).click(function(){
        window.location = $(this).find("a.js-click-item-link").attr("href");
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * productSlider
    // *
    // *
    var $productSlider = $(".js-product-slider");

    if ($productSlider.length){
        $productSlider.slick({
            fade: false,
            dots: false,
            arrows: true,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 8000,
            slidesToShow: 3,
            slidesToScroll: 1,
            prevArrow: $(".js-product-slider-nav-prev"),
            nextArrow: $(".js-product-slider-nav-next"),
            responsive: [
                {
                    breakpoint: 1300,
                    settings: {
                        arrows: false,
                    }
                },
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 2,
                        arrows: false,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 2,
                        arrows: false,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        arrows: false,
                    }
                }
            ]
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * productSlider
    // *
    // *
    var $awardSlider = $(".js-award-slider");

    if ($awardSlider.length){
        $awardSlider.slick({
            fade: true,
            dots: false,
            arrows: true,
            autoplay: false,
            speed: 1000,
            autoplaySpeed: 8000,
            prevArrow: $(".js-award-slider-nav-prev"),
            nextArrow: $(".js-award-slider-nav-next"),
            responsive: [
                {
                    breakpoint: 850,
                    settings: {
                        arrows: false,
                    }
                }
            ]
        });
    }




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * contentSlider
    // *
    // *
    var $contentSlider = $(".js-content-slider");

    if ($contentSlider.length){
        $contentSlider.slick({
            fade: true,
            dots: true,
            arrows: false,
            autoplay: true,
            speed: 1000,
            rows: 0,
            autoplaySpeed: 8000,
            responsive: []
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sticky header
    // *
    // *
    $(window).scroll(function() {
		if($(window).scrollTop() >= 40) {
			$(".js-header-sticky").addClass("active");
		}else {
			$(".js-header-sticky").removeClass("active");
		}
	});



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 200; /* Distance to Browserbottom to start effect */

    function animateIn(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("animation-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }
    $(window).scroll(function() {
        animateIn();
    });
    animateIn();



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *
    $(".js-accordion-button").click(function(){
        $(this).parents(".js-accordion-item").toggleClass("active").find(".js-accordion-content").slideToggle();
        $(this).parents(".js-accordion-item").siblings().removeClass("active").find(".js-accordion-content").slideUp();
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * filter products
    // *
    // *
    if ($(".js-filter-elements").length) {
        var mixer = mixitup('.js-filter-elements', {
            selectors: {
                target: '.js-filter-element'
            },
            multifilter: {
                enable: true // enable the multifilter extension for the mixer
            }
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * filter
    // *
    // *
    $(".js-filter").on('mouseleave', function () {
        $(this).removeClass("open");
    });

    // mobile filters
    $(".js-filter-label-default").on('click', function () {
        $(this).parents(".js-filter").addClass("open");
    });

    // set filter
    $(".js-filter-option-item").click(function(){
        var $parent = $(this).parents(".js-filter"),
            filterTitle = $(this).data("filter-title");

        $parent.addClass("active");
        $parent.removeClass("open");
        $(this).addClass("active").siblings().removeClass("active");
        $parent.find(".js-filter-label-default").addClass("hidden");
        $parent.find(".js-filter-label-active").removeClass("hidden").find(".js-filter-label-active-text").html(filterTitle);

    });

    // reset filter
    $(".js-filter-label-active").click(function(){
        var $parent = $(this).parents(".js-filter"),
            filterGroupName = $parent.data("filter-group");

        $parent.removeClass("active");
        $parent.find(".js-filter-label-default").removeClass("hidden");
        $parent.find(".js-filter-label-active").addClass("hidden");
        $parent.find(".js-filter-option-item").removeClass("active");

        // reset filter group
        mixer.setFilterGroupSelectors(filterGroupName, []);
        mixer.parseFilterGroups();
    });

    // remove all unused filter options
    $(".js-filter-option-item").each(function(){
        var filter = $(this).data("filter");

        if ($(filter).length < 1) {
            $(this).remove();
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * scroll next
    // *
    // *
    $(".js-scroll-next-button").click(function(){
        var nextElement = $(this).parents(".js-scroll-next-parent").next();
        scrollTo(nextElement);
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * toggleContentButton
	// *
	// *
    if ($(".js-toggle-content-button").length) {
        $(".js-toggle-content-button").on("change", function() {
            var id = $(this).data("toggle-content-id"),
                type = $(this).data("toggle-content-type"),
                action = $(this).data("toggle-content-action");

            if (type == 'select') {
                if (action == 'show') {
                    $("#" + id).slideDown();
                }else {
                    $("#" + id).slideUp();
                }
            }else {
                if ($(this).prop("checked")) {
                    $("#" + id).slideUp();
                }else {
                    $("#" + id).slideDown();
                }
            }

        });

        $(".js-toggle-content-button:checked").trigger('change');
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * ie11 Fix SVG + opbject fit
    // *
    // *
    svg4everybody();
    objectFitImages();



});
